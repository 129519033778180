/**
 * 登录用户操作封装
 */
const userKey = 'vue_user';
export default {
    //判断是否登录
    isLogin() {
        var info = $.cookie(userKey);
        if (info == undefined)
            return false;
        return true;
    },
    //保存登录信息,后台操作
    save(info) {
        //在实际项目中后台存登录信息
        $.cookie.json = true;
        if (info.checked) {
            $.cookie(userKey, info, { path: '/', expires: 7 });
        } else {
            $.cookie(userKey, info, { path: '/' });
        }
    },
    //获取登录信息
    getInfo() {
        $.cookie.json = true;
        var info = $.cookie(userKey);
        return info;
    },
    //清空登录信息
    clear: function () {
        $.removeCookie(userKey, { path: '/' }); // => true
    }
}
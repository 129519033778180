<template>
  <el-container class="wrapper">
    <el-header class="header" :class="isCollapse ? 'headerCollapse' : ''">
      <div class="logo">后台管理系统</div>
      <div class="topcenter">
        <div class="topleft">
          <el-button
            type="primary"
            @click="menuToggle()"
            circle
            class="icon icon-list"
          ></el-button>
        </div>
        <div class="topright">
          <el-dropdown :hide-on-click="false" @command="handleCommand">
            <span class="el-dropdown-link" style="corsor: pointer">
              <span class="name">{{ userinfo.nickName }}</span>
              <img :src="userinfo.photo" alt />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>我的消息</el-dropdown-item>
              <el-dropdown-item command="update_pwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout" divided
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 修改密码框 -->
        <el-dialog title="修改登录密码" :visible.sync="dialogFormVisible">
          <el-form
            :model="pwdForm"
            ref="pwdForm"
            :rules="pwdRules"
            label-width="100px"
            status-icon
          >
            <el-form-item
              label="旧密码"
              prop="oldPwd"
              :rules="[{ required: true, message: '请输入旧密码' }]"
            >
              <el-input
                type="password"
                v-model="pwdForm.oldPwd"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input
                type="password"
                v-model="pwdForm.newPwd"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="重复密码" prop="checkPass">
              <el-input
                type="password"
                v-model="pwdForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button
              type="primary"
              @click="updatePwd('pwdForm')"
              :loading="loading"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <div class="clearfix"></div>
      </div>
    </el-header>
    <el-container>
      <!-- 左侧菜单 -->
      <el-aside class="menu" :class="isCollapse ? 'menuCollapse' : ''">
        <el-menu
          default-active="1"
          :collapse="isCollapse"
          :collapse-transition="false"
        >
          <el-submenu
            :index="item.id + '_'"
            :key="item.id"
            v-for="item in navList"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <router-link
              :to="child.linkUrl"
              :key="child.id"
              v-for="child in item.children"
            >
              <el-menu-item :index="child.id + '_'">
                <i :class="child.icon" />
                <span>{{ child.name }}</span>
              </el-menu-item>
            </router-link>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <transition name="el-fade-in-linear" mode="out-in">
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import user from "../plugins/user.js";
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    //验证码确认验证码处理
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        if (this.pwdForm.checkPass) {
          this.$refs.pwdForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      loading: false, //是否加载中
      isCollapse: false, //菜单折叠
      dialogFormVisible: false, //修改密码框
      userinfo: {}, //当前登录用户信息
      pwdForm: {}, //修改密码对象
      pwdRules: {
        newPwd: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      navList: [], //菜单列表
    };
  },
  components: {},
  methods: {
    menuToggle() {
      this.isCollapse = !this.isCollapse;
    },
    //处理下拉菜单事件
    handleCommand: function (type) {
      if (type == "logout") {
        this.logoutClick();
      } else if (type == "update_pwd") {
        this.dialogFormVisible = true;
      }
    },
    //修改登录密码
    updatePwd(formName) {
      var _this = this;
      //表单验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          //提交吼他修改处理
          var link = url.getAccount("updatepwd");
          var info = _this.pwdForm;
          info.oldPwd = _this.pwdForm.oldPwd;
          info.newPwd = _this.pwdForm.newPwd;
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              setTimeout(() => {
                _this.dialogFormVisible = false;

                _this.$message({
                  type: "success",
                  message: res.msg,
                });
              }, 1000);
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //退出系统
    logoutClick() {
      var _this = this;
      this.$confirm("确定要退出系统吗？", "提示", {})
        .then(() => {
          user.clear(); //清空登录凭证
          this.$message({
            type: "success",
            message: "退出成功",
            onClose() {
              _this.$router.replace("/login");
            },
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已经取消",
          });
        });
    },
    //获取菜单列表
    getNavList() {
      var _this = this;
      var link = url.getSys("GetCurrent_Nav");
      $.get(link, {},(res) => {
        _this.navList = res;
      });
    },
  },
  created() {
    //判断用户是否登录
    if (user.isLogin()) {
      console.info("登录成功");
      this.userinfo = user.getInfo();
      //获取菜单列表
      this.getNavList();
    } else {
      console.info("还没登录");
      this.$router.replace("/login");
    }
  },
};
</script>
